import * as reactSpring from '@react-spring/three';
import * as drei from '@react-three/drei';
import * as fiber from '@react-three/fiber';

import { motion } from 'framer-motion';
import {
  ShaderGradient as ReactShaderGradient,
  ShaderGradientCanvas as ReactShaderGradientCanvas
} from 'shadergradient';

const ShaderGradient = () => (
  <>
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{ duration: 3 }}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        zIndex: 1
      }}
    ></motion.div>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 3 }}
    >
      <ReactShaderGradientCanvas
        importedFiber={{ ...fiber, ...drei, ...reactSpring }}
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 0,
          backgroundColor: 'black'
        }}
      >
        <ReactShaderGradient
          control="props"
          color1="#5d00ce"
          color2="#00063f"
          color3="#000000"
          grain="on"
          envPreset="city"
          lightType="3d"
          shader="defaults"
          type="waterPlane"
          range="enabled"
          rangeStart={0}
          rangeEnd={1000}
          toggleAxis={false}
          wireframe={false}
          frameRate={10}
          brightness={0.75}
          cameraZoom={1}
          uAmplitude={0}
          uDensity={1.1}
          uFrequency={0}
          uSpeed={0.07}
          uStrength={2.4}
          uTime={0.2}
          cAzimuthAngle={90}
          cDistance={3.9}
          cPolarAngle={115}
          positionX={-0.5}
          positionY={0.1}
          positionZ={0}
          rotationX={50}
          rotationY={0}
          rotationZ={235}
          zoomOut={false}
          enableTransition={false}
        />
      </ReactShaderGradientCanvas>
    </motion.div>
  </>
);

export default ShaderGradient;
