import { useState } from 'react';

import { Text } from '@cavela/ui';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  children: string;
  subheading?: string;
  editMode?: boolean;
  canEdit?: boolean;
  name?: string;
  setName?: (newValue: string) => void;
  onSave?: () => void;
  onEditClick?: () => void;
}

const PageHeading = ({
  children,
  subheading = '',
  editMode = false,
  name,
  setName,
  canEdit,
  onSave,
  onEditClick
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setName) {
      setName(e.target.value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSave && name) {
      onSave();
      setIsHovered(false);
    }
  };

  return (
    <div className="w-full">
      {editMode ? (
        <div className="relative inline-flex items-center">
          <div className="relative w-full">
            <input
              value={name}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className="text-black text-[2.5em] font-bold bg-transparent border-2 border-black rounded-md px-2 py-1 pr-16 focus:outline-none cursor-text w-full"
              style={{ color: 'black', fontSize: '2.5em' }}
              placeholder="Your Product"
            />
            <button
              className="absolute  right-2 top-1/2 transform -translate-y-1/2 bg-border-light-gray-2 rounded-lg p-2 disabled:cursor-not-allowed disabled:opacity-50 hover:scale-105 transition-all duration-300 ease-in-out"
              disabled={!name}
              onClick={onSave}
            >
              <CheckIcon style={{ color: 'black' }} />
            </button>
          </div>
        </div>
      ) : (
        <Text heading style={{ color: 'black', fontSize: '2.5em' }}>
          <span
            className={`${canEdit ? 'cursor-pointer' : ''} flex items-center`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
              if (onEditClick) {
                onEditClick();
              }

              setIsHovered(false);
            }}
          >
            {children}
            {isHovered && canEdit && (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 inline-block"
              >
                <path
                  d="M2.5 14.5501V17.0835C2.5 17.3168 2.68333 17.5001 2.91667 17.5001H5.45C5.55833 17.5001 5.66667 17.4585 5.74167 17.3751L14.8417 8.28346L11.7167 5.15846L2.625 14.2501C2.54167 14.3335 2.5 14.4335 2.5 14.5501ZM17.2583 5.8668C17.5833 5.5418 17.5833 5.0168 17.2583 4.6918L15.3083 2.7418C14.9833 2.4168 14.4583 2.4168 14.1333 2.7418L12.6083 4.2668L15.7333 7.3918L17.2583 5.8668Z"
                  fill="#6E747D"
                />
              </svg>
            )}
          </span>
        </Text>
      )}
      {subheading && (
        <>
          <div className="my-2">
            <Text color="#6E747D">{subheading}</Text>
          </div>
        </>
      )}
    </div>
  );
};

export default PageHeading;
