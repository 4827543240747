import { useState } from 'react';

import { Button, Flex, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { registerLoginCopy } from '../../data/copies';
import useLogin from '../../hooks/useLogin';
import { customAnchor } from '../../styles/markdownMapper';
import CustomMarkdown from '../CustomMarkdown/CustomMarkdown';
import ErrorNotification from '../ErrorNotification/ErrorNotification';

export default function AuthForm({
  onFinishSubmit,
  copies,
  isRegister
}: {
  onFinishSubmit: (formInfo: { action: string; userEmail: string }) => void;
  copies: registerLoginCopy;
  isRegister: boolean;
}) {
  const { refreshTokenOrCreateUser, loading } = useLogin();
  const [showNotification, setShowNotification] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [passcodeError, setPasscodeError] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  async function handleSubmit() {
    const payload: { email: string; signup_access_code?: string } = {
      email: mantineForm.values.email
    };

    if (isRegister) {
      payload.signup_access_code = passcode;
    }

    const response = await refreshTokenOrCreateUser(payload);

    if (response.action) {
      onFinishSubmit({
        action: response.action,
        userEmail: mantineForm.values.email
      });
    }
    if (response.error) {
      setErrorMsg(response.error);
      setShowNotification(true);
    }
  }

  const mantineForm = useForm({
    initialValues: {
      email: ''
    },
    validate: {
      email: (value) => {
        return value.length === 0 ? copies.errorEmailRequired : null;
      }
    }
  });

  return (
    <Flex direction="column" align="center" justify="center" w="100%" maw={400}>
      <Title
        order={1}
        mb="sm"
        w="100%"
        className="font-title -ml-2"
        style={{ fontWeight: 900 }}
      >
        {copies.formTitle}
      </Title>

      <form
        style={{ width: '100%' }}
        onSubmit={mantineForm.onSubmit(handleSubmit)}
      >
        <TextInput
          {...mantineForm.getInputProps('email')}
          label={copies.formSubtitle}
          placeholder="name@company.com"
          size="md"
          data-testid="email-input"
          type="email"
          radius="md"
          styles={{
            label: {
              marginBottom: '.5rem'
            }
          }}
        />

        {isRegister && (
          <TextInput
            label="Access Code"
            placeholder="Your code (sent in the invite email)"
            size="md"
            data-testid="passcode-input"
            radius="md"
            className="mt-4"
            onChange={(event) => {
              setPasscode(event.currentTarget.value);
              setPasscodeError('');
            }}
            error={passcodeError}
          />
        )}

        <Button
          mt="sm"
          size="md"
          type="submit"
          loading={loading}
          data-testid="cta-button"
          className="rounded-full hover:scale-105 transition-all duration-300"
        >
          {copies.cta}
        </Button>
      </form>

      {isRegister && (
        <>
          <div
            className="text-left w-full ml-4 mt-4 text-sm text-light-gray"
            style={{ fontWeight: '900' }}
          >
            <div>
              Don't have an access code?{' '}
              <a
                href="https://www.cavela.com/#form"
                className="text-p-cavela-blue"
                style={{ fontWeight: '900' }}
              >
                Join waitlist
              </a>
            </div>
            <div className="mt-2">
              Already have an account?{' '}
              <a
                href="/login"
                className="text-p-cavela-blue"
                style={{ fontWeight: '900' }}
              >
                Sign in
              </a>
            </div>
          </div>

          <div className="fixed bottom-4 text-center">
            <CustomMarkdown
              components={{
                a: (props) => customAnchor({ ...props, c: 'cavela-primary.6' })
              }}
            >
              {copies.toOtherForm}
            </CustomMarkdown>
          </div>
        </>
      )}

      {!isRegister && (
        <div
          className="text-left w-full ml-4 mt-2 text-sm text-light-gray"
          style={{ fontWeight: '900' }}
        >
          <div className="mt-2">
            Don't have an account?{' '}
            <a
              href="/register"
              className="text-p-cavela-blue"
              style={{ fontWeight: '900' }}
            >
              Sign up
            </a>
          </div>
        </div>
      )}

      {showNotification && <ErrorNotification>{errorMsg}</ErrorNotification>}
    </Flex>
  );
}
