import { useContext, useEffect, useState } from 'react';

import { Button, Text } from '@cavela/ui';

import {
  FileDragDrop,
  FloatingBox,
  SelectedFilesContainer,
  ShaderGradient,
  SuccessErrorNotification
} from '..';
import i18next from 'i18next';

import { useForm } from '@mantine/form';

import ProductContext from '../../context/Product';
import useFiles from '../../hooks/useFiles';
import { trackOnboardingStep } from '../../services/analytics/analytics';
import {
  ONBOARDING_PRODUCT_FILES_COMPLETED,
  ONBOARDING_PRODUCT_FINAL_STEP_STARTED
} from '../../services/analytics/eventCatalog';
import { MappedLifecycleStage } from '../../types/ProductTypes';
import { allFilesWithinSize } from '../FileUpload/FileUpload';

const StepProductFiles = ({
  onNext,
  onPrev,
  isFirst
}: {
  onNext: () => void;
  onPrev: () => void;
  isFirst: boolean;
}) => {
  const { t } = i18next;
  const { productDetail, currentStage } = useContext(ProductContext);
  const [showNotification, setShowNotification] = useState(false);
  const { uploadFiles, setFiles, files, error } = useFiles();
  const [key, setKey] = useState(0);

  useEffect(() => {
    trackOnboardingStep(ONBOARDING_PRODUCT_FINAL_STEP_STARTED);
  }, []);

  const onFormSubmit = () => mantineForm.onSubmit(onSubmit);

  const onSubmit = async (formValues: {
    files: File[];
    captions: string[];
  }) => {
    if (productId && stageId) {
      const response = await uploadFiles({
        files: formValues.files,
        captions: formValues.captions,
        productId,
        stageId
      });

      if (!response) return;

      if (response.error) {
        setShowNotification(true);

        return;
      }

      trackOnboardingStep(ONBOARDING_PRODUCT_FILES_COMPLETED);
      onNext();

      return;
    }

    return;
  };

  const onDropFiles = (files: File[]) => {
    // This is a workaround to force the component to re-render to prevent the drag and drop from not updating the UI when deleting and selecting the same file
    setKey(key + 1);

    mantineForm.setValues((values) => ({
      ...values,

      files: values.files ? [...values.files, ...files] : files,

      captions: values.captions
        ? [...values.captions, ...files.map(() => '')]
        : files.map(() => '')
    }));

    const formValues = mantineForm.getValues();

    setFiles({
      files: formValues.files,
      captions: formValues.captions,
      productId,
      stageId
    });
  };

  const onAddCaption = (index: number, caption: string) => {
    mantineForm.setValues((values) => {
      const captions = values.captions ? [...values.captions] : [];

      captions[index] = caption;

      return {
        ...values,
        captions
      };
    });
  };

  const onDelete = (index: number) => {
    mantineForm.setValues((values) => ({
      ...values,

      files: values.files?.filter((_, i) => i !== index),
      captions: values.captions?.filter((_, i) => i !== index)
    }));
  };

  const mantineForm = useForm({
    initialValues: {
      files: (files || []) as File[],
      captions: [] as string[],
      stage: [] as MappedLifecycleStage[]
    },
    validate: {
      files: (value) =>
        allFilesWithinSize(value) ? null : t('excedeedFileSizeError')
    }
  });

  if (!productDetail) return <div />;

  const { stages = [], productId = '' } = productDetail || {};
  const stageId = stages?.[currentStage].id;

  const { files: formFiles = [] } = mantineForm?.values || {};
  const hasFiles = formFiles.length > 0;
  const heading = t('createRFQ');

  return (
    <div className="flex flex-col md:flex-rowitems-center space-between gap-2">
      <div className="bg-white w-full h-full md:w-auto md:h-auto md:rounded-none left-0 right-0 fixed right-[50%] left-0 top-0 bottom-0 p-4 overflow-auto">
        <div className="absolute left-0 right-0 top-0 h-64 p-4 z-50">
          <div className="my-4">
            <span className="">{heading}</span>
          </div>
        </div>
        <div className="flex flex-col w-full h-full mt-12">
          {showNotification && (
            <SuccessErrorNotification
              isError={true}
              heading={t('errorGenericTitle')}
              text={error || t('addFileError')}
            />
          )}
          <div className="flex flex-col gap-2 justify-center h-full">
            <form onSubmit={onFormSubmit()} className="w-full">
              <FileDragDrop onDrop={onDropFiles} />
              {hasFiles && (
                <div className="flex flex-col gap-1 mt-2">
                  <Text heading style={{ fontSize: '1.5rem' }}>
                    {t('selectedFiles')}
                  </Text>
                  <SelectedFilesContainer
                    selectedFiles={formFiles}
                    onAddCaption={onAddCaption}
                    onDelete={onDelete}
                  />
                  <Text color="red">{mantineForm?.errors?.files}</Text>
                </div>
              )}
              <div className="flex">
                <Button
                  type="submit"
                  appearance={!hasFiles ? 'disabled' : 'primary'}
                  disabled={!hasFiles}
                >
                  {t('next')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="hidden md:block bg-black w-auto h-auto rounded-none left-0 right-0 fixed left-[50%] right-0 top-0 bottom-0">
        <div className="absolute w-full h-full z-50 text-center flex flex-col items-center justify-center gap-2">
          <FloatingBox />
          <Text control color="white" style={{ fontSize: '1.25em' }}>
            Your Product
          </Text>
        </div>
        <ShaderGradient />
      </div>
    </div>
  );
};

export default StepProductFiles;
