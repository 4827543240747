import { PageWrapper, ProductsDisplay } from '../../components';

const Home = () => (
  <PageWrapper>
    <div className="flex flex-col items-center justify-around gap-4 w-full h-full text-base">
      <ProductsDisplay />
    </div>
  </PageWrapper>
);

export default Home;
