import { UpdateItem } from '..';

import { MappedUpdate } from '../../types/UpdateTypes';

const UpdatesContent = ({
  updates = [],
  onUpdate
}: {
  updates: MappedUpdate[];
  onUpdate: () => void;
}) => {
  if (!updates) return;

  const uniqueUpdates = updates?.filter(
    (update, index, self) => index === self.findIndex((t) => t.id === update.id)
  );

  return (
    <>
      {uniqueUpdates.map((update) => (
        <UpdateItem key={update.id} update={update} onUpdate={onUpdate} />
      ))}
    </>
  );
};

export default UpdatesContent;
