import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from 'src/context/Auth';
import { API_URL } from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

import { RFQ } from './props';

const get_rfqs_url = `${API_URL}/api/rfqs?status=draft`;

const useLogic = ({ setIsRfqsEmpty }: { setIsRfqsEmpty: Function }) => {
  const { isLoading } = useContext(AuthContext);
  const [rfqs, setRfqs] = useState<RFQ[]>([]);

  const navigate = useNavigate();

  const fetchRfqs = async () => {
    const response = await fetchWithAuth(get_rfqs_url, {
      method: 'GET'
    });

    const data = await response.json();
    if (data.length === 0) {
      setIsRfqsEmpty(true);
    }
    setRfqs(data);
  };

  const redirectToRfq = (rfqId: number) => {
    navigate(`/new-rfq?rfq_id=${rfqId}`);
  };

  useEffect(() => {
    fetchRfqs();
  }, []);

  return {
    isLoading,
    rfqs,
    redirectToRfq
  };
};

export default useLogic;
