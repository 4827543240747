import { useEffect, useState } from 'react';

import useUpdates from './useUpdates';

const DEFAULT_TAB = 'Overview';

interface SupplierUpdates {
  [key: string]: any;
}

const useSupplierUpdates = ({ productId }: { productId: string }) => {
  const [supplierUpdates, setSupplierUpdates] = useState<SupplierUpdates>({});

  const { updates, loading, errorCode, fetchUpdates } = useUpdates({
    fetchOnLoad: true,
    productId
  });

  useEffect(() => {
    const updatedSupplierUpdates: SupplierUpdates = {
      [DEFAULT_TAB]: []
    };

    for (const productUpdate of updates) {
      for (const updateItem of productUpdate.updates) {
        if (updateItem?.id) {
          const key: any = updateItem?.supplierCloak;

          if (key) {
            if (!updatedSupplierUpdates[key]) {
              updatedSupplierUpdates[key] = [];
            }

            updatedSupplierUpdates[key].push(updateItem);
          }

          if (
            !updatedSupplierUpdates[DEFAULT_TAB]?.find(
              ({ id = -1 }) => id === updateItem.id
            )
          ) {
            updatedSupplierUpdates[DEFAULT_TAB].push(updateItem);
          }
        }
      }
    }

    setSupplierUpdates(updatedSupplierUpdates);
  }, [updates]);

  return {
    supplierUpdates,
    loading,
    errorCode,
    fetchUpdates
  };
};

export default useSupplierUpdates;
