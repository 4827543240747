import { useContext } from 'react';

import i18next from 'i18next';

import { Button, Flex, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import ProductContext from '../../context/Product';
import useFiles from '../../hooks/useFiles';
import { MAX_FILE_UPLOAD_SIZE_MB } from '../../settings';
import StageSectionWrapper from '../StageSectionWrapper/StageSectionWrapper';
import FileDragDrop from './FileDragDrop';
import { SelectedFilesContainer } from './SelectedFilesContainer';

const { t } = i18next;

export function allFilesWithinSize(files: File[]) {
  const maxSize = 1024 * 1024 * MAX_FILE_UPLOAD_SIZE_MB;

  return files.every((file) => file.size <= maxSize);
}

type FormSubmit = (formValues: {
  files: File[];
  captions?: string[];
}) => Promise<void>;

interface Props {
  noSubmit?: boolean;
  onSubmit?: FormSubmit;
  setStagedFiles?: any;
  setStagedCaptions?: any;
}

const FileUpload = ({
  noSubmit,
  onSubmit,
  setStagedFiles,
  setStagedCaptions
}: Props) => {
  const { productDetail, currentStage, refetch } = useContext(ProductContext);
  const productId = productDetail?.productId || '';

  const {
    uploadFiles,
    loading: uploadLoading,
    error: uploadError,
    setFiles
  } = useFiles();

  const handleSubmit = async (formValues: {
    files: File[];
    captions?: string[];
  }) => {
    const stageId = productDetail?.stages[currentStage].id;

    if (productId && stageId) {
      const response = await uploadFiles({
        files: formValues.files,
        captions: formValues.captions,
        productId,
        stageId
      });

      if (response && !response.error) {
        mantineForm.reset();
        refetch();

        return;
      }
    }
  };

  const mantineForm = useForm({
    initialValues: {
      files: [] as File[],
      captions: [] as string[]
    },
    validate: {
      captions: (value) => value.every((caption) => caption.length <= 200),
      files: (value) =>
        allFilesWithinSize(value) ? null : t('excedeedFileSizeError')
    }
  });

  if (!mantineForm.initialized) {
    mantineForm.initialize(mantineForm.values);
  }

  const onFormSubmit = (event: any) => {
    event?.preventDefault();

    return (onSubmit || handleSubmit)(mantineForm.values);
  };

  const onAddCaption = (index: number, caption: string) => {
    const stageId = productDetail?.stages[currentStage].id || '';

    mantineForm.setValues((values) => {
      if (values?.captions) {
        values.captions[index] = caption;
      }

      return values;
    });

    setFiles({
      files: mantineForm.values.files,
      captions: mantineForm.values.captions,
      productId,
      stageId
    });

    if (setStagedFiles) {
      setStagedFiles(mantineForm.values.files || []);
    }

    if (setStagedCaptions) {
      setStagedCaptions(mantineForm.values.captions || []);
    }
  };

  const onAddFiles = (files: File[]) => {
    const stageId = productDetail?.stages[currentStage].id || '';

    mantineForm.setValues((values) => {
      // Append new files to existing ones
      const updatedFiles = [...(values.files || []), ...files];
      // Extend captions array with empty strings for new files
      const updatedCaptions = [
        ...(values.captions || []),
        ...Array(files.length).fill('')
      ];

      values.files = updatedFiles;
      values.captions = updatedCaptions;

      return values;
    });

    setFiles({
      files: mantineForm.values.files,
      captions: mantineForm.values.captions,
      productId,
      stageId
    });

    if (setStagedFiles) {
      setStagedFiles(mantineForm.values.files || []);
    }

    if (setStagedCaptions) {
      setStagedCaptions(mantineForm.values.captions || []);
    }
  };

  const onDelete = (index: number) => {
    mantineForm.setValues((values) => ({
      ...values,

      captions: values.captions?.filter((_, i) => i !== index),
      files: values.files?.filter((_, i) => i !== index)
    }));
  };

  return (
    <StageSectionWrapper>
      <form onSubmit={onFormSubmit}>
        <FileDragDrop onDrop={onAddFiles} />
        {mantineForm.values.files.length > 0 && (
          <Flex direction="column" gap="md">
            <Title mt="md" size="md">
              {t('selectedFiles')}
            </Title>
            <SelectedFilesContainer
              selectedFiles={mantineForm.values.files}
              onAddCaption={onAddCaption}
              onDelete={onDelete}
            />
            {!noSubmit && (
              <Button
                type="submit"
                disabled={!mantineForm.values.files}
                loading={uploadLoading}
                data-testid="submit-uploads"
              >
                {t('uploadFiles')}
              </Button>
            )}
            <Text size="sm" c="red">
              {mantineForm.errors.files || uploadError}
            </Text>
          </Flex>
        )}
      </form>
    </StageSectionWrapper>
  );
};

export default FileUpload;
