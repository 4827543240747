import { Text } from '@cavela/ui';

import Avatar from '../Avatar/Avatar';

const ApproveRejectLockup = ({
  isApproved = true,
  isRejected = false,
  message = ''
}) => {
  const approved = isApproved && !isRejected;
  const approvedText = 'Approved.';
  const rejectedText = 'Rejected.';

  return (
    <div className="flex flex-col items-start justify-between gap-3 mt-2">
      <Text color="var(--mantine-color-cavela-green-5)">
        <strong>{approved ? approvedText : rejectedText}</strong>
      </Text>
      {message && (
        <div className="flex gap-3 max-w-[75%]">
          <Avatar src="" alt="user profile" />
          <div className="bg-[var(--mantine-color-cavela-shadow-2)] p-3 rounded-lg">
            <Text small>{(message || '').trim()}</Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApproveRejectLockup;
