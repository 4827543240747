import { Link as RouterLink } from 'react-router-dom';

import { Text } from '@cavela/ui';

import { Text as MantineText, Title } from '@mantine/core';

import { Link } from '../components';

export const pRegular = (props: any) => <MantineText {...props} />;
export const pSmall = (props: any) => (
  <MantineText component="p" size="sm" {...props} />
);
export const customAnchor = (props: any) => (
  <Link underline="none" className="text-sm" {...props}>
    {props.children}
  </Link>
);
export const quoteMarkdown = (props: any) => <Text small {...props} />;
export const h3Title = (props: any) => (
  <Title order={3} {...props} textWrap="pretty" />
);
export const mdNavLink = (props: any) => (
  <RouterLink to={props.href} {...props} />
);
export const mdTitle = (props: any) => <Title {...props} textWrap="pretty" />;
