import { useState } from 'react';

import { Button } from '@cavela/ui';
import { CheckRounded, MessageRounded, Padding } from '@mui/icons-material';

import { Modal } from '..';
import i18next from 'i18next';

import { Flex, Button as MantineButton, Textarea } from '@mantine/core';

const { t } = i18next;

const ApprovalQuestion = (questionProps: any) => {
  const { onSubmit } = questionProps;
  const [isOpen, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [isApprove, setIsApprove] = useState(false);

  const onSubmitReason = (approval: boolean) => {
    const approvalValue = approval ? 'approved' : 'rejected';

    onSubmit([approvalValue, reason]);
    setOpen(false);
    setReason('');
  };

  const onClickReject = () => {
    setIsApprove(false);
    setOpen(true);
  };

  const onClickApprove = () => {
    setIsApprove(true);
    setOpen(true);
  };

  return (
    <div className="flex flex-wrap">
      <Button
        type="primary"
        onClick={onClickApprove}
        style={{
          padding: '0.35rem 1.5rem'
        }}
      >
        <CheckRounded fontSize="small" htmlColor="white" />
        &nbsp;Approve
      </Button>
      <Button
        type="primary"
        variant="outline"
        onClick={onClickReject}
        style={{
          padding: '0.35rem 1.5rem'
        }}
      >
        <MessageRounded
          fontSize="small"
          htmlColor="var(--mantine-color-cavela-primary-5)"
        />
        &nbsp;Reject
      </Button>
      <Modal opened={isOpen} centered onClose={() => setOpen(false)}>
        <div className="flex flex-col gap-6">
          <Textarea
            data-testid="rejection-reason-input"
            placeholder={isApprove ? 'Add your feedback' : 'Add a comment'}
            label={isApprove ? 'Feedback' : 'Reason for rejection'}
            description={
              isApprove
                ? 'Your feedback is important to us'
                : 'The supplier will reply with a new approval request'
            }
            value={reason}
            rows={5}
            required
            onChange={(event) => setReason(event.currentTarget.value)}
            styles={{
              label: {
                fontSize: '1em',
                fontWeight: 'bold'
              },
              description: {
                fontSize: '.9em',
                marginBottom: '1rem'
              }
            }}
          />

          <Flex gap="sm" direction={{ base: 'column', md: 'row' }}>
            <MantineButton
              variant="cancel"
              w="100%"
              onClick={() => setOpen(!isOpen)}
            >
              {t('cancel')}
            </MantineButton>
            <MantineButton
              data-testid="reject-question-send-cta"
              onClick={() => onSubmitReason(isApprove)}
              form="product-file-upload"
              w="100%"
              disabled={!reason}
            >
              {t('send')}
            </MantineButton>
          </Flex>
        </div>
      </Modal>
    </div>
  );
};

export default ApprovalQuestion;
