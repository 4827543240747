import { color } from 'framer-motion';

const variants = {
  default: {
    backgroundColor: 'white',
    color: 'black'
  },
  caution: {
    backgroundColor: '#FEF3C7',
    color: '#D97706'
  },
  success: {
    backgroundColor: '#DAF8E6',
    color: '#1A8245'
  },
  alert: {
    backgroundColor: '#D0F0FD',
    color: '#0B76B7'
  },
  context: {
    backgroundColor: 'transparent',
    color: '#6E747D'
  },
  draft: {
    backgroundColor: 'rgb(229, 231, 235)',
    color: 'black'
  }
};

const Label = ({
  small = false,
  variant = 'default',
  children,
  style = {}
}) => (
  <div
    className="rounded overflow-hidden"
    style={{
      display: 'inline-block',
      fontWeight: 500,
      ...variants[variant],
      ...style
    }}
  >
    <div
      className="text-xs font-500 leading-3 flex items-center justify-center gap-0.5 mx-auto px-3 py-2"
      style={{
        ...(!small
          ? {}
          : {
              fontSize: '.69em',
              padding: '.1rem .4rem'
            })
      }}
    >
      {children}
    </div>
  </div>
);

export default Label;
