import { Flex, Radio } from '@mantine/core';

export default function SingleChoiceQuestion(questionProps: any) {
  const {
    isAnswered,
    sendError,
    sendLoading,
    questionType,
    options,
    fileUploadsEnabled: fileuploadsenabled,

    ...rest
  } = questionProps;

  return (
    <Radio.Group
      name="single-choice-question"
      error={sendError}
      errorProps={sendError}
      {...rest}
    >
      <Flex direction="column" className="mt-2" gap="xs">
        {options.map((option: string) => (
          <Radio
            value={option}
            key={option}
            label={option.charAt(0).toUpperCase() + option.slice(1)}
            disabled={sendLoading || isAnswered}
            size="sm"
          ></Radio>
        ))}
      </Flex>
    </Radio.Group>
  );
}
