import { useCallback, useEffect, useState } from 'react';

import i18next from 'i18next';

import { captureException } from '../services/reporting';
import { API_URL } from '../settings';
import { CustomError } from '../types/errorTypes';
import { MappedProductDetail } from '../types/ProductTypes';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import { getLastActiveStageIndex } from '../utils/getLastActiveStage';
import { productMapper } from '../utils/productMappers';

const { t } = i18next;

export default function useProduct({
  productId,
  fetchOnLoad = false
}: {
  productId: string | undefined;
  fetchOnLoad?: boolean;
}) {
  const [productDetail, setProductDetail] = useState<MappedProductDetail>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentStage, setCurrentStage] = useState(0);
  const [lastActiveStageIndex, setLastActiveStageIndex] = useState(0);

  async function editProduct({
    productId,
    data
  }: {
    productId: string;
    data: any;
  }): Promise<{ error: string | null }> {
    setLoading(true);
    try {
      const productDetailUrl = `${API_URL}/api/products/${productId}`;

      const response = await fetchWithAuth(productDetailUrl, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status !== 200) {
        throw new CustomError({
          message: `Error editing product`,
          statusCode: response.status
        });
      }

      const responseData = await response.json();
      const mappedProductDetail = productMapper(responseData);

      setProductDetail((prevState) => ({
        ...prevState,
        ...mappedProductDetail
      }));
      return { error: null };
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };
      captureException({ error, extra });
      return { error: error.message };
    } finally {
      setLoading(false);
    }
  }

  function handleStageSelection(stageId?: string) {
    const index = productDetail?.stages.findIndex(
      (stage) => stage.id === stageId
    );
    if (index !== undefined) {
      setCurrentStage(index);
    }
  }

  const fetchProductDetail = useCallback(
    async ({
      productId,
      refreshStage = false
    }: {
      productId: string;
      refreshStage: boolean;
    }) => {
      setError(null);
      setLoading(true);
      try {
        const productDetailUrl = `${API_URL}/api/products/${productId}`;

        const response = await fetchWithAuth(productDetailUrl);

        if (response.status !== 200) {
          throw new CustomError({
            message: `Error fetching product detail`,
            statusCode: response.status
          });
        }

        const data = await response.json();

        const mappedProductDetail = productMapper(data);

        const lastActiveStage = getLastActiveStageIndex({
          productStages: mappedProductDetail.stages
        });

        setProductDetail((prevState) => ({
          ...prevState,
          ...mappedProductDetail
        }));

        // Updates the last active in case that after the fetch the stages have changed.
        setLastActiveStageIndex(lastActiveStage);

        // This is a param because not all refetches require the stage to be updated.
        if (refreshStage) setCurrentStage(lastActiveStage);
      } catch (error: any) {
        const extra = {
          statusCode: error.statusCode
        };

        setError(t('errorProduct404'));

        captureException({ error, extra });
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const refetch = useCallback(
    (options: { refreshStage?: boolean } = {}): void => {
      const { refreshStage = false } = options;
      if (productId) {
        fetchProductDetail({ productId, refreshStage });
      }
    },
    [productId, fetchProductDetail]
  );

  /* This is the main effect that fetches the product detail whenever the productId changes. it also refreshes the stage -- It's an initial load*/
  useEffect(() => {
    if (productId && fetchOnLoad) {
      fetchProductDetail({ productId, refreshStage: true });
    }
  }, [fetchProductDetail, productId, fetchOnLoad]);

  return {
    fetchProductDetail,
    productDetail,
    loading,
    currentStage,
    lastActiveStageIndex,
    handleStageSelection,
    refetch,
    editProduct,
    error
  };
}
