import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UseSidebarStoreProps {
  isCollapsed: boolean;
  setIsCollapsed: Function;
  toggleIsCollapsed: Function;
  isScreenResizing: boolean;
  setScreenResizing: Function;
}

const useSidebarStore = create<UseSidebarStoreProps>()(
  persist(
    (set, get) => ({
      isCollapsed: true,
      setIsCollapsed: (isCollapsed: boolean) => set({ isCollapsed }),
      toggleIsCollapsed: () => set({ isCollapsed: !get().isCollapsed }),
      isScreenResizing: false,
      setScreenResizing: (isScreenResizing: boolean) =>
        set({ isScreenResizing })
    }),
    {
      name: 'sidebar-store',
      partialize: (state) => ({ isCollapsed: state.isCollapsed })
    }
  )
);

export default useSidebarStore;
