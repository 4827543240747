import { useState } from 'react';

import { captureException } from '../services/reporting';
import { API_URL } from '../settings';
import { CustomError } from '../types/errorTypes';

const loginOrCreateURL = `${API_URL}/api/users`;

export default function useLogin() {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  async function refreshTokenOrCreateUser({
    email,
    signup_access_code
  }: {
    email: string;
    signup_access_code?: string;
  }): Promise<{ action: string | null; error: string | null }> {
    setErrorMsg(null);
    try {
      setLoading(true);
      const response = await fetch(loginOrCreateURL, {
        method: 'POST',
        body: JSON.stringify({ email, signup_access_code }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const data = await response.json();
        setErrorMsg(data.detail);

        throw new CustomError({
          message: data.detail,
          statusCode: response.status
        });
      }
      switch (response.status) {
        case 200:
          return { action: 'login', error: null };
        case 201:
          return { action: 'register', error: null };
        default:
          throw new CustomError({
            message: `Unexpected status code received when creating user/refreshing token: ${response.status} ${email}-${signup_access_code}`,
            statusCode: response.status
          });
      }
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };
      captureException({ error, extra });

      return { action: null, error: error.message || errorMsg };
    } finally {
      setLoading(false);
    }
  }

  return { refreshTokenOrCreateUser, loading, error: errorMsg };
}
