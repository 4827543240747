import React from 'react';

import { Image } from '@mui/icons-material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import getFormattedDate from 'src/utils/getFormattedDate';

import Badge from '../Badge/Badge';
import Label from '../Label/Label';
import { RfqDraftCardProps } from './props';

const RfqDraftCard = ({
  id,
  image,
  documentName,
  questionsLength,
  createdAt,
  redirectToRfq
}: RfqDraftCardProps) => {
  return (
    <div
      key={id}
      className="bg-white shadow-lg rounded-2xl p-4 relative hover:animate-hover transition-all duration-300 cursor-pointer"
      onClick={() => redirectToRfq(id)}
    >
      <div className="flex flex-col h-full">
        {image ? (
          <img
            src={image}
            alt={documentName}
            className="w-full h-[170px] object-cover mb-3 rounded-lg"
          />
        ) : (
          <div className="w-full h-[170px] flex items-center justify-center bg-border-light-gray-2 rounded-lg mb-3">
            <Image htmlColor="#6E747D" fontSize="large" />
          </div>
        )}

        <div className="mb-3">
          {' '}
          {/*           <Badge variant="draft" text="Draft" /> */}
          <Label variant="draft">
            <EditRoundedIcon
              style={{
                fontSize: '12px'
              }}
            />
            <span>Draft</span>
          </Label>
        </div>

        <h3 className="text-md font-600 text-black mb-3 overflow-hidden whitespace-normal break-words">
          {documentName || 'Untitled'}
        </h3>

        <div className="mt-auto">
          <p className="text-light-gray mb-2">
            Progress: {questionsLength} answers
          </p>

          <div className="flex items-center">
            <img src="/icons/calendar.svg" alt="calendar" className="mr-2" />
            <p className="text-gray-600">
              Created: {getFormattedDate(createdAt)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RfqDraftCard;
