const getFormattedDate = (date: string) => {
  if (!date) return '';
  const dateObj = new Date(date);
  const locale = 'en-US';

  // If you want to get the locale from the browser
  // const locale = Intl.DateTimeFormat().resolvedOptions().locale;

  const dateString = dateObj.toLocaleDateString(locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
  return dateString;
};

export default getFormattedDate;
