import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Text } from '@cavela/ui';

import i18next from 'i18next';

import { Button, Divider, Flex, Transition } from '@mantine/core';
import { useForm } from '@mantine/form';

import useProduct from '../../hooks/useProduct';
import { trackQuestionAnswered } from '../../services/analytics/analytics';
import { MappedQuestion, QuestionType } from '../../types/QuestionTypes';
import truncateFileName from '../../utils/truncateFileName';
import Avatar from '../Avatar/Avatar';
import Link from '../Link/Link';
import ApprovalQuestion from './ApprovalQuestion';
import ApproveRejectLockup from './ApproveRejectLockup';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import SingleChoiceQuestion from './SingleChoiceQuestion';
import SuccessErrorNotification from './SuccessErrorNotification';
import TextQuestion from './TextQuestion';

const { t } = i18next;

const NOTIF_DELAY = 4000;

const questionTypes: QuestionType[] = [
  'multiple_choice',
  'single_choice',
  'text',
  'trigger_sourcing', //@deprecated but left here for retrocompatibility
  'approval'
];

const questionTypeMap = {
  multiple_choice: MultipleChoiceQuestion,
  single_choice: SingleChoiceQuestion,
  text: TextQuestion,
  trigger_sourcing: TextQuestion, //@deprecated but left here for retrocompatibility
  approval: ApprovalQuestion
};

interface makeQuestionComponentProps {
  questionType: QuestionType;
  [key: string]: any;
}

interface QuestionFormProps {
  questionContent: MappedQuestion;
  onQuestionSend: (props: any) => Promise<any>;
  productId?: string;
  questionId: number;
  stageId: string;
  onAnswered: (answered: boolean) => void;
  answered: boolean;
  setChanged?: Dispatch<SetStateAction<boolean>>;
}

function makeQuestionComponent(props: makeQuestionComponentProps) {
  const {
    questionType,
    answeredAt,
    createdAt,
    questionMarkdown,
    descriptionMarkdown,
    trackingEventId,

    ...rest
  } = props;
  if (questionTypes.includes(questionType)) {
    const QuestionComponent =
      questionTypeMap[questionType as keyof typeof questionTypeMap];
    return <QuestionComponent {...rest} />;
  }
  return null;
}

const Answer = ({ children = '' }) => (
  <div className="flex gap-3 max-w-[75%]">
    <Avatar src="" alt="user profile" />
    <div className="bg-[var(--mantine-color-cavela-shadow-2)] p-4 rounded-lg">
      <Text small>{children}</Text>
    </div>
  </div>
);

const QuestionForm = ({
  questionContent,
  questionId,
  productId,
  stageId = '',
  onQuestionSend,
  onAnswered,
  answered,
  setChanged
}: QuestionFormProps) => {
  const APPROVED = 'approved';
  const REJECTED = 'rejected';
  const SUBMIT_SUCCESS_HEADING = t('submitSuccessHeading');
  const SUBMIT_SUCCESS = t('submitSuccess');
  const SUBMIT_ERROR_HEADING = t('submitErrorHeading');
  const SUBMIT_ERROR = t('submitError');

  const { refetch, productDetail } = useProduct({
    productId,
    fetchOnLoad: true
  });

  const [sendError, setSendError] = useState<string | null>(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const location = useLocation();

  useEffect(() => {
    setIsActive(!answered && !success && !error);
  }, [answered, success, error]);

  useEffect(() => {
    if (productDetail) {
      const stage = productDetail.stages.find(({ id }) => id === stageId);

      if (stage?.questions?.length) {
        const question = stage.questions[questionId];

        if (question?.files) {
          setFiles(question.files);
        }
      }
    }
  }, [questionId, stageId, productDetail]);

  const mantineForm = useForm({
    initialValues: {
      answer: questionContent.answer || ''
    },
    onValuesChange: () => {
      if (setChanged) {
        setChanged(true);
      }
    }
  });

  const onSubmit = async (values: string | string[]) => {
    setSendError(null);
    setSendLoading(true);

    const sendResponse = await onQuestionSend({
      questionId,
      values,
      stageId,
      questionType: questionContent.questionType
    });

    trackQuestionAnswered(location.pathname);
    setSendLoading(false);

    const { error } = sendResponse;

    setSendError(error);

    if (!error) {
      setError(false);
      setSuccess(true);
      onAnswered(true);
      refetch();

      setTimeout(() => {
        setError(false);
        setSuccess(false);
      }, NOTIF_DELAY);

      return;
    }

    setError(true);
    setSuccess(false);
  };

  const questionProps = {
    sendLoading,

    ...questionContent,
    ...mantineForm.getInputProps('answer')
  };

  const checkAnswerContent = (answer: string | string[]): any => {
    if (Array.isArray(answer)) {
      const [approval, reason] = answer;
      const isApproved = approval === APPROVED;
      const isRejected = approval === REJECTED;

      if (isApproved || isRejected) {
        return (
          <ApproveRejectLockup
            isApproved={isApproved}
            isRejected={isRejected}
            message={reason}
          />
        );
      }

      return <Answer>{(answer || '').join(', ').trim()}</Answer>;
    }

    return <Answer>{(answer || '').trim()}</Answer>;
  };

  const isApproval = questionContent.questionType === 'approval';

  return (
    <>
      <Transition
        mounted={success}
        transition="pop"
        duration={600}
        timingFunction="ease"
      >
        {(style) => (
          <SuccessErrorNotification
            isSuccess={true}
            heading={SUBMIT_SUCCESS_HEADING}
            text={SUBMIT_SUCCESS}
            setSuccess={setSuccess}
            style={style}
          />
        )}
      </Transition>
      <Transition
        mounted={error}
        transition="pop"
        duration={600}
        timingFunction="ease"
      >
        {(style) => (
          <SuccessErrorNotification
            isSuccess={false}
            heading={SUBMIT_ERROR_HEADING}
            text={SUBMIT_ERROR}
            setSuccess={setSuccess}
            style={style}
          />
        )}
      </Transition>
      {!isActive && (
        <Text color="black">
          {checkAnswerContent(questionContent?.answer || '')}
          {files.length > 0 && (
            <Divider
              mt="lg"
              label={t('additionalFiles').toUpperCase()}
              labelPosition="left"
            />
          )}
          {files.map(({ id, fileUrl, fileName, caption }) => (
            <div className="px-1 py-1" key={id}>
              <Link className="text-base" href={fileUrl}>
                {truncateFileName(fileName, 48)}
              </Link>
              {caption && <Text>{caption.slice(0, 200)}</Text>}
            </div>
          ))}
        </Text>
      )}
      {isActive && (
        <>
          {isApproval ? (
            <ApprovalQuestion loading={sendLoading} onSubmit={onSubmit} />
          ) : (
            <form
              onSubmit={mantineForm.onSubmit((values) =>
                onSubmit(values.answer)
              )}
            >
              <Flex direction="column" w="100%" pt="md">
                {makeQuestionComponent(questionProps)}
                <Flex pt="md">
                  <Button
                    data-testid="question-send-button"
                    type="submit"
                    loading={sendLoading}
                    disabled={sendLoading || mantineForm.values.answer === ''}
                    radius="xl"
                  >
                    {t('save')}
                  </Button>
                  <Flex flex={1} align="center" ml="md">
                    {sendError && (
                      <Text small color="red">
                        {sendError}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default QuestionForm;
